$red: #eb5424;
$blue: #5a4fcf;
$green: #19a302;
$yellow: #dbc12d;

:root {
  --primary: #404040 !important;
  --text-color: #333;
  --text-color1: #666;
  --text-color2: #999;
  --text-color3: #bbb;
  --link-color: #222;
  --default: #e0e0e0;
  --secondary: #666;
  --gray: #f2f2f2;
  --red: #eb5424;
  --red-light: #fdf1ed;
  --yellow: #dbc12d;
  --yellow-light: #fffdee;
  --blue: #5a4fcf;
  --blue-light: #e6f0fb;
  --green: #19a302;
  --green-light: #f4fff2;

  --divider-color: rgba(0, 0, 0, 0.1);

  --filter-color: #808080;
  --filter-active-color: #202020;

  // speical vars
  --btn-border-radius: 0.375rem;
  --btn-box-shaodw: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --btn-secondary-text-color: rgba(55, 65, 81, 1);
  --btn-secondary-background-color: #fff;
  --btn-secondary-border-color: rgba(209, 213, 219, 1);
  --btn-secondary-active-background-color: #fff;
  --btn-secondary-active-border-color: rgba(209, 213, 219, 1);
  --btn-secondary-active-text-color: rgba(55, 65, 81, 1);
  --btn-secondary-hover-background-color: rgba(249, 250, 251, 1);
  --btn-secondary-hover-border-color: rgba(209, 213, 219, 1);

  --form-control-background-color: #fff;
  --form-control-border-color: rgba(209, 213, 219, 1);
  --form-control-focus-border-color: rgba(99, 102, 241, 1);
  --form-control-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --form-control-disabled-background-color: #e9ecef;

  --input-group-background-color: rgba(249, 250, 251);
  --input-group-border-color: rgba(209, 213, 219);

  --body-background-color: #f5f6f9;

  --navbar-background-color: #fff;
  --navbar-text-color: #404040;
  --navbar-shadow-color: rgba(0, 0, 0, 0.06);
  --navbar-fixed-shadow-color: rgba(0, 0, 0, 0.09);
  --navbar-item-link-active-background-color: #f2f2f2;
  --navbar-item-active-text-color: #eb5424;

  --navbar-topic-title-background-color: #fff;

  --navbar-brand-text-color: #666;
  --navbar-brand-text-strong-color: #2275da;

  --navbar-dropdown-show-background-color: rgba(0, 0, 0, 0.04);

  --sub-navbar-background-color: #fff;
  --sub-navbar-shadow-color: rgba(0, 0, 0, 0.02);

  --card-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.04),
    0 1px 2px 0 rgba(0, 0, 0, 0.03);
  --card-border-color: hsla(210, 8%, 51%, 0.09);
  --card-inset-border-color: hsla(210, 8%, 51%, 0.15);
  --card-background-color: #fff;
  --card-title-secondary-color: #777;

  --dropdown-menu-background-color: #fff;
  --dropdown-menu-border-color: #e5e7eb;
  --dropdown-menu-box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

  --dropdown-menu-text-color: rgba(55, 65, 81, 1);
  --dropdown-item-active-text-color: #222;
  --dropdown-menu-divider-color: rgba(243, 244, 246, 1);
  --dropdown-item-active-background-color: rgba(243, 244, 246, 1);

  --modal-background-color: #fff;
  --modal-shadow-color: rgba(0, 0, 0, 0.07);

  --list-group-item-border-color: #f7f7f7;

  --table-border-color: #e0e0e0;

  --topic-item-border-color: #f0f0f0a3;
  --topic-item-hover-background-color: #f9f9f9;

  --label-awesome-background-color: #ffcc8b24;
  --label-awesome-link-color: #adaaa8;

  --opts-text-color: #666;
  --opts-link-color: #808080;
  --opts-link-hover-background-color: #f0f0f0;
  --opts-active-icon-color: #111;

  --scrollbar-thumb-color: rgb(41, 36, 36);
  --scrollbar-track-color: #e5e5e5;

  --nav-pills-active-background-color: #2275da;
  --nav-pills-active-text-color: #fff;
}

$font-size-base: 0.875rem;
$font-family-sans-serif: Helvetica, Arial, "PingFang SC", "Noto Sans", Roboto,
  "Microsoft Yahei", sans-serif;

/* Animations */
.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.5);
  }

  70% {
    -webkit-transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  50% {
    opacity: 1;
    transform: scale(1.5);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}
